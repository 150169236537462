import React, {useEffect} from "react"

function Mentions({channelMembers, inputValue, setInputValue}) {
  const [mentionsOpen, setMentionOpen] = React.useState(false)

  useEffect(() => {
    setMentionOpen(inputValue.slice(-1) === '@')
  }, [inputValue])

  if (!mentionsOpen) {
    return null
  }

  const setMentionedPerson = (member) => {
    setInputValue(prevState => prevState + member.name)
  }

  return (
    <div style={{ backgroundColor: '#444444', padding: '0 15px', borderRadius: 8, position: 'absolute', bottom: 80, border: '1px solid #737373' }}>
      {channelMembers.map((member) => {
        return (
          <p style={{ cursor: 'pointer' }} onClick={() => setMentionedPerson(member)}>{member.name}</p>
        )
      })}
    </div>
  )

}

export default Mentions