import * as signalR from "@microsoft/signalr";

class ChatIts {
  #token = null
  #connection = null
  messages = {}
  channels = null
  currentChannel = ''
  myData = {}


  constructor(token) {
    this.#token = token
  }

  async connect() {
    this.#connection = new signalR.HubConnectionBuilder()
      .withUrl("https://chat.itsharkz.com/signalr/chat", {
        accessTokenFactory: () => `bearer ${this.#token}`
      })
      .configureLogging(signalR.LogLevel.Trace)
      .withAutomaticReconnect()
      .build()
    await this.#connection.start()
    return this.#connection
  }

  async stopConnection() {
    await this.#connection.stop()
  }

  eventListeners(clientListeners) {
    this.#connection.on("error", data => {
      console.log('error', data)
      alert(data.error)
    });

    this.#connection.on("getCustomers", data => {
      console.log('getCustomers', data)
      clientListeners.getCustomers(data)
    });

    this.#connection.on("EveryoneNotRead", data => {
      console.log('EveryoneNotRead', data)
      clientListeners.notReadChannels(data)
    })

    this.#connection.on("getChannel", data => {
      console.log('getChannel', data)
      clientListeners.getChannel(data)
    });

    this.#connection.on("getMessages", data => {
      console.log('getMessages', data)
      clientListeners.getMessages(data)
    });

    this.#connection.on("getChannels", data => {
      console.log('getChannels', data)
      clientListeners.getChannels(data)
    });

    this.#connection.on("receiveMessage", data => {
      console.log('receiveMessage', data)
      clientListeners.receiveMessage(data, this.currentChannel)
    });

    this.#connection.on("typing", data => {
      console.log('typing', data)
      clientListeners.onTyping(data, this.currentChannel)
    });

    this.#connection.on("hello", data => {
      console.log('hello', data)
      this.myData = data
    });

    this.#connection.on('channelCreated', data => {
      console.log('channelCreated', data)
      clientListeners.channelCreated(data)
    })

    this.#connection.on("OnlineStatusUpdated", data => {
      console.log('OnlineStatusUpdated', data)
      clientListeners.memberOnlineUpdate(data)
    })

    this.#connection.on("FindCustomer", data => {
      console.log('FindCustomer', data)
      clientListeners.findCustomer(data)
    })
  }

  sendMessage(channelId, message, mentions) {
    this.#connection.invoke("message", {
      ChannelId: channelId,
      message: message,
      mentions: mentions
    })
  }

  findMembers(value) {
    this.#connection.invoke("FindCustomer", {
      partialName: value
    })
  }

  joinChannel(channelId) {
    this.#connection.invoke("joinChannel", {
      ChannelId: channelId
    })
  }

  createChannel(channelName, customerIds) {
    this.#connection.invoke("createChannel", {
      name: channelName,
      customerIds: customerIds
    })
  }

  getChannel(channelId, take = 10, skip = 0) {
    this.#connection.invoke("channel", {
      ChannelId: channelId,
      Take: take,
      Skip: skip
    })
  }

  getMembers() {
    this.#connection.invoke("getCustomers")
  }

  getMessages(channelId, take = 10, skip = 0) {
    this.#connection.invoke("getMessages", {
      ChannelId: channelId,
      Take: take,
      Skip: skip
    })
  }

  getChannels() {
    this.#connection.invoke("channels")
  }

  leaveChannel(channelId) {
    this.#connection.invoke("leaveChannel", {
      ChannelId: channelId
    })
  }

  typing(typing) {
    this.#connection.invoke("typing", {
      ChannelId: this.currentChannel,
      typing: typing
    })
  }
}

export default ChatIts;
