import React, {useEffect} from "react"
import ChatIts from "./lib/index"
import InfiniteScroll from "react-infinite-scroll-component";
import Mentions from "./components/mentions";

let timer;

function debounce(func, timeout = 300) {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const OverflowLastMessage = {
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}

function App() {
  const [instance, setInstance] = React.useState()
  const [token, setToken] = React.useState('')
  const [channelsList, setChannelsList] = React.useState([])
  const [messages, setMessages] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')
  const [typingPeople, setTypingPeople] = React.useState([])
  const [typingPeopleToShow, setTypingPeopleToShow] = React.useState([])
  const [channelMembers, setChannelMembers] = React.useState([])
  const [notReadChannels, setNotReadChannels] = React.useState([])
  const [hasMoreMessages, setHasMoreMessages] = React.useState(true)
  const [showAddChannelModal, setShowAddChannelModal] = React.useState(false)
  const [allMembers, setAllMembers] = React.useState([])

  const [foundMembers, setFoundMembers] = React.useState([])
  const messagesContainer = React.useRef()

  useEffect(() => {
    if (!instance) return
    instance.connect().then(r => {
      instance.eventListeners({
        getChannels: (data) => setChannelsList(data.items),
        getChannel: (data) => {
          setMessages(data.messages)
          setChannelMembers(data.customers || [])
          messagesContainer.current && messagesContainer.current.scroll(0, Number.MAX_SAFE_INTEGER)
          setNotReadChannels(prevState => prevState.filter(channel => channel.channelId !== data.channelId))
        },
        receiveMessage: (data) => receiveMessage(data),
        getMessages: (data) => {
          setMessages(prevData => [...prevData, ...data.messages])
          if (data.messages.length < 1) {
            setHasMoreMessages(false)
          }
        },
        getCustomers: (data) => {
          setAllMembers(data)
        },
        channelCreated: (data) => {
          setMessages(data.messages)
          setChannelMembers(data.customers || [])
          messagesContainer.current && messagesContainer.current.scroll(0, Number.MAX_SAFE_INTEGER)
          setNotReadChannels(prevState => prevState.filter(channel => channel.channelId !== data.channelId))
          instance.currentChannel = data.channelId
          setHasMoreMessages(true)
        },
        onTyping: (data) => {
          setTypingPeople(prevState => {
            if (data.typing && !prevState.find(item => item.customer.id === data.customer.id)) {
              setTypingPeopleToShow([...prevState, data].filter(item => item.customer.id !== instance.myData.id))
              return [...prevState, data]
            } else if (!data.typing && prevState.find(item => item.customer.id === data.customer.id)) {
              setTypingPeopleToShow(prevState.filter(item => item.customer.id !== data.customer.id))
              return prevState.filter(item => item.customer.id !== data.customer.id)
            }
            setTypingPeopleToShow([...prevState].filter(item => item.customer.id !== instance.myData.id))
            return prevState
          })
        },
        findCustomer: (data) => {
          setFoundMembers(data)
        },
        memberOnlineUpdate: (data) => {
          setChannelMembers(prevState => {
            let newArray = prevState
            newArray = newArray.map(member => {
              if (member.id === data.customerId) {
                return {...member, online: data.online}
              } else {
                return member
              }
            })

            return newArray
          })
        },
        notReadChannels: (data) => {
          if (notReadChannels.find(notReadChannel => notReadChannel.channelId === data.channelId) || data.channelId === instance.currentChannel) {
            //
          } else {
            setNotReadChannels(prevState => [...prevState, data])
          }
        }
      })
      instance.getChannels()
    })
    return () => {
      instance.stopConnection()
    }
  }, [instance])

  const receiveMessage = data => {
    if (data.channelId === instance.currentChannel) {
      setMessages(prevState => [data, ...prevState])
      messagesContainer.current && messagesContainer.current.scroll(0, Number.MAX_SAFE_INTEGER)
    }
    setChannelsList(prevState => {
      const oldChannels = prevState
      const channelToUpdate = oldChannels.find(oldChannel => oldChannel.id === data.channelId)
      if (channelToUpdate) {
        channelToUpdate.lastMessage = data
      }
      return [...oldChannels]
    })
  }

  const chooseChannel = (id) => {
    instance.currentChannel = id
    instance.getChannel(id)
    setHasMoreMessages(true)
  }

  const findMembers = (value) => {
    instance.findMembers(value)
  }

  const checkIfSomeoneIsMentioned = (message) => {
    const mentionedMembers = []
    channelMembers.forEach(member => {
      if (message.includes(`@${member.name}`)) {
        mentionedMembers.push({id: member.id, name: member.name})
      }
    })
    return mentionedMembers
  }

  const sendMessage = () => {
    if (inputValue.length < 1) {
      return
    }

    instance.sendMessage(instance.currentChannel, inputValue, checkIfSomeoneIsMentioned(inputValue))
    setInputValue('')
  }

  const loadMoreMessages = () => {
    instance.getMessages(instance.currentChannel, 10, messages.length)
  }

  const createInstance = () => {
    instance && instance.stopConnection()
    setMessages([])
    setChannelsList([])
    setInstance(new ChatIts(token))
  }

  const textInputOnchange = (value) => {
    setInputValue(value)
    if (!typingPeople.find(item => item.customer.id === instance.myData.id)) {
      instance.typing(true)
    }
    debounce(() => instance.typing(false), 2000)()
  }

  const formatMessage = (message) => {
    let newMessage = message.message
    if (message.mentions && message.mentions.length > 0) {
      message.mentions.forEach(mention => {
        if (message.message.includes(mention.name)) {
          newMessage = newMessage.replaceAll(`@${mention.name}`, `<span style="font-weight: bold;">@${mention.name}</span>`)
        }
      })
    }
    return <span dangerouslySetInnerHTML={{ __html: newMessage}} />
  }


  return (
    <div>
      <div>
        <div style={{display: 'flex', alignItems: 'stretch', marginBottom: 25, padding: '5px 15px'}}>
          <input value={token} onChange={(e) => setToken(e.target.value)} placeholder={'token'}
                 style={{marginBottom: 0}}/>
          <button style={{margin: 0}} onClick={createInstance}>connect</button>
        </div>
        <div style={{
          display: 'flex',
          height: 500,
          overflow: 'hidden',
          margin: '0 auto',
          maxWidth: 996,
          borderRadius: 16,
          position: 'relative'
        }}>
          <div style={{width: '25%', padding: '0 15px', backgroundColor: '#737373', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0'}}>
              <div>Channels list</div>
              <div onClick={() => {
                instance.getMembers()
                setShowAddChannelModal(true)
              }}>Add +</div>
            </div>
            <input/>
            <div style={{ flex: 1, overflow: 'auto' }}>
              {channelsList.map(channel => (<div style={{
                padding: '5px 15px',
                marginBottom: 15,
                background: instance.currentChannel === channel.id ? '#595959' : '#8c8c8c',
                borderRadius: 8,
                cursor: 'pointer',
                position: 'relative'
              }} onClick={() => chooseChannel(channel.id)}>
                <p style={{margin: 0}}>{channel.name}</p>
                {notReadChannels.find(notReadChannel => notReadChannel.channelId === channel.id) && <div style={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  width: 10,
                  height: 10,
                  backgroundColor: 'yellow',
                  borderRadius: 10
                }}/>}
                {channel.lastMessage && <p style={{
                  margin: 0,
                  fontStyle: 'italic',
                  fontSize: 11, ...OverflowLastMessage
                }}>{channel.lastMessage.author.name + ': ' + channel.lastMessage.message}</p>}
              </div>))}
            </div>
          </div>

          <div style={{
            width: '50%',
            padding: '0 15px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#595959'
          }}>
            <p>Current channel</p>
            <div ref={messagesContainer} id={'scrollableDiv'} style={{
              overflow: 'auto',
              maxHeight: '100%',
              height: 500,
              display: 'flex',
              flexDirection: 'column-reverse'
            }}>
              <InfiniteScroll
                dataLength={messages.length}
                next={loadMoreMessages}
                style={{display: 'flex', flexDirection: 'column-reverse'}} //To put endMessage and loader to the top.
                inverse={true}
                hasMore={hasMoreMessages}
                loader={<h4>Siadanie na butle...</h4>}
                scrollableTarget="scrollableDiv"
                initialScrollY={Number.MAX_SAFE_INTEGER}
              >
                {messages.map(message => (
                  <div style={{marginBottom: 15}}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: instance.myData.id === message.author.id ? 'flex-end' : 'flex-start'
                    }}>
                      <p style={{margin: 0, fontWeight: 'bold', width: 'auto'}}>{message.author.name}</p>
                      <div style={{
                        backgroundColor: '#404040',
                        borderRadius: 8,
                        padding: '5px 15px',
                        wordBreak: 'break-all'
                      }}>
                        {formatMessage(message)}
                      </div>
                      <span style={{
                        fontSize: 11,
                        fontStyle: 'italic'
                      }}>{new Date(message.date).toLocaleDateString()} {new Date(message.date).toLocaleTimeString()} </span>
                    </div>
                  </div>
                ))}
                {messages.length < 1 && <p style={{textAlign: 'center'}}>There is no messages yet</p>}
              </InfiniteScroll>
            </div>

            {typingPeopleToShow.filter(user => user.channelId === instance.currentChannel).length > 0 && <div>
              <span
                style={{fontSize: 11}}>{typingPeopleToShow.filter(user => user.channelId === instance.currentChannel)[0].customer.name} is typing</span>
            </div>}
            {typingPeopleToShow.filter(user => user.channelId === instance.currentChannel).length > 1 && <div>
              <span style={{fontSize: 11}}>Several people are typing</span>
            </div>}
            {typingPeopleToShow.filter(user => user.channelId === instance.currentChannel).length === 0 &&
            <div style={{height: 16}}/>}
            <Mentions channelMembers={channelMembers} inputValue={inputValue} setInputValue={setInputValue} />
            <div style={{display: 'flex', alignItems: 'stretch', marginTop: 'auto', paddingBottom: 10, width: '100%'}}>
              <input value={inputValue} onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  sendMessage()
                }
              }} onChange={(e) => {
                textInputOnchange(e.target.value)
              }} style={{marginBottom: 0, width: '100%'}}/>
              <button onClick={sendMessage} style={{margin: 0}}>Send</button>
            </div>
          </div>
          <div style={{width: '25%', padding: '0 15px', backgroundColor: '#737373'}}>
            <p>Channel members</p>
            {channelMembers.map(member => <div
              style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <span>{member.name}</span>
              <span
                style={{width: 15, height: 15, backgroundColor: member.online ? '#00cf00' : 'red', borderRadius: 15}}/>
            </div>)}
          </div>

          {showAddChannelModal && <div onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setShowAddChannelModal(false)
          }}
            style={{position: 'absolute', inset: 0, backgroundColor: 'rgba(0,0,0,0.6)', backdropFilter: 'blur(5px)'}}>
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '5px 15px',
              backgroundColor: '#737373',
              borderRadius: 8
            }} onClick={(e) => e.stopPropagation()}>
              <p style={{borderBottom: '1px solid #595959', paddingBottom: 10}}>Create new channel</p>
              <div>
                <form style={{ border: 0, padding: 0 }} onSubmit={(e) => {
                  e.preventDefault()
                  const members = []
                  e.target.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
                    if (checkbox.checked) {
                      members.push(checkbox.id)
                    }
                  })
                  instance.createChannel(e.target[0].value, members)
                  setShowAddChannelModal(false)
                }
                }>
                  <div>
                    <p style={{marginBottom: 0}}>Channel name</p>
                    <input />
                  </div>
                  <div>
                    <input onChange={(e) => debounce(() => findMembers(e.target.value))()}/>
                    {foundMembers.map((member) => {
                      if (member.id === instance.myData.id) {
                        return null
                      }
                      return (
                        <label htmlFor={member.id} style={{cursor: 'pointer'}}>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>{member.name}</span>
                            <span><input type={'checkbox'} id={member.id}/></span>
                          </div>
                        </label>
                      )
                    })}
                  </div>
                  <button type={'submit'} style={{width: '100%', padding: '5px 15px'}}>Create</button>

                </form>
              </div>
            </div>
          </div>}

        </div>
      </div>
    </div>
  );
}

export default App;
